/* eslint-disable prettier/prettier */
import { ChevronRight, ChevronsUpDown, LogOut } from 'lucide-react';

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarRail,
  useSidebar
} from '../../atoms';

import Logo from '../../../../public/logo/logo-full.png';
import IconLogo from '../../../../public/logo/logo.jpeg';

interface Props {
  user: any;
  handleLogout: VoidFunction;
  navDatas: any;
  activeTab?: string;
}

export function AppSidebar({ user, handleLogout, navDatas, activeTab }: Props) {
  const team = {
    fullLogo: Logo,
    iconLogo: IconLogo,
    name: 'Growdash'
  };

  const { open } = useSidebar();

  return (
    <Sidebar collapsible='icon'>
      <SidebarHeader className='max-h-[64px] border-b border-border'>
        <SidebarMenu>
          <SidebarMenuItem>
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger asChild>
                <SidebarMenuButton
                  size='lg'
                  className='data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground'
                >
                  {open ? (
                    <div
                      className='flex aspect-square items-center w-full justify-center rounded-lg bg-center bg-contain bg-no-repeat bg-[url("../public/logo/logo-full.png")] dark:bg-[url("../public/logo/logo-white.png")]'
                        
                    />
                  ) : (
                    <div className='flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground'>
                      <img src={IconLogo} alt={team.name} />
                    </div>
                  )}
                  <ChevronsUpDown className='ml-auto' />
                </SidebarMenuButton>
              </DropdownMenuTrigger>
            </DropdownMenu>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            {navDatas
              .filter((item: any) => item.subheader !== 'management')
              .map((item: any, index: number) => {
                return (
                  <Collapsible
                    key={item.subheader + index}
                    asChild
                    defaultOpen={
                      item.items?.some(
                        (subItem: any) => subItem.path === activeTab
                      ) ||
                      activeTab?.split('/').filter(Boolean)[0] ===
                        item.subheader?.toLowerCase() ||
                      true
                    }
                    className='group/collapsible'
                  >
                    <SidebarMenuItem>
                      <CollapsibleTrigger asChild>
                        <SidebarMenuButton
                          className='hover:bg-muted transition-colors duration-200'
                          tooltip={item.title}
                        >
                          {item.icon && <item.icon />}
                          <span className='capitalize'>{item.subheader}</span>
                          <ChevronRight className='ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90' />
                        </SidebarMenuButton>
                      </CollapsibleTrigger>
                      <CollapsibleContent>
                        <SidebarMenuSub>
                          {item.items?.map((subItem: any) => (
                            <SidebarMenuSubItem key={subItem.title}>
                              <SidebarMenuSubButton
                                className={
                                  subItem.path === activeTab
                                    ? 'bg-muted transition-colors duration-200'
                                    : 'hover:bg-muted transition-colors duration-200'
                                }
                                asChild
                              >
                                <a href={subItem.path}>
                                  <span className='capitalize'>
                                    {subItem.title}
                                  </span>
                                </a>
                              </SidebarMenuSubButton>
                            </SidebarMenuSubItem>
                          ))}
                        </SidebarMenuSub>
                      </CollapsibleContent>
                    </SidebarMenuItem>
                  </Collapsible>
                );
              })}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger asChild>
                <SidebarMenuButton
                  size='lg'
                  className='data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground border border-border'
                >
                  <Avatar className='h-8 w-8 rounded-lg'>
                    <AvatarImage src={user.avatar} alt={user.name} />
                    <AvatarFallback className='rounded-lg'>
                      {user.name?.charAt(0).toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                  <div className='grid flex-1 text-left text-sm leading-tight'>
                    <span className='truncate font-semibold'>{user.name}</span>
                    <span className='truncate text-xs'>{user.username}</span>
                  </div>
                  <ChevronsUpDown className='ml-auto size-4' />
                </SidebarMenuButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className='w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg'
                side='bottom'
                align='end'
                sideOffset={4}
              >
                <DropdownMenuLabel className='p-0 font-normal'>
                  <div className='flex items-center gap-2 px-1 py-1.5 text-left text-sm'>
                    <Avatar className='h-8 w-8 rounded-lg'>
                      <AvatarImage src={user.avatar} alt={user.name} />
                      <AvatarFallback className='rounded-lg'>
                        {user.name?.charAt(0).toUpperCase()}
                      </AvatarFallback>
                    </Avatar>
                    <div className='grid flex-1 text-left text-sm leading-tight'>
                      <span className='truncate font-semibold'>
                        {user.name}
                      </span>
                      <span className='truncate text-xs'>{user.username}</span>
                    </div>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  {navDatas
                    .find((item: any) => item.subheader === 'management')
                    ?.items?.map((item: any, i: number) => (
                      <a href={item.path} key={i}>
                        <DropdownMenuItem key={item.title}>
                          {item.icon && <item.icon />}
                          {item.title}
                        </DropdownMenuItem>
                      </a>
                    ))}
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleLogout}>
                  <LogOut />
                  Log out
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
