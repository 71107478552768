import {
  Avatar,
  AvatarFallback,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Separator,
  SidebarTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@growdash/design-system/atoms';
import { Breadcrumbs, SearchBar } from '@growdash/design-system/molecules';
import { useBreadcrumbs } from '@hooks/use-breadcrumbs.tsx';
import React from 'react';
import { ThemeToggle } from '../../components/theme-toggle.tsx';

const bg_colors = [
  'bg-[#640D5F]',
  'bg-yellow-500',
  'bg-green-500',
  'bg-blue-500',
  'bg-indigo-500',
  'bg-purple-500',
  'bg-pink-500'
];

export default function Header({ navDatas, clients, handleChangeToken }: any) {
  const breadcrumbs = useBreadcrumbs();
  const [open, setOpen] = React.useState(false);

  return (
    <header className='border-b border-border h-[64px] bg-background z-[9] sticky top-0 flex shrink-0 items-center justify-between gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12'>
      <div className='flex items-center gap-2 px-4'>
        <SidebarTrigger className='-ml-1' />
        <Separator orientation='vertical' className='mr-2 h-4' />
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </div>

      <div className='flex items-center gap-2 px-4'>
        <Tooltip>
          <TooltipTrigger>
            <div onClick={() => setOpen(true)} className='flex items-center'>
              {clients.slice(0, 4).map((item: any, index: any) => (
                <div
                  className={`w-6 h-6 rounded-full ${bg_colors[index]} flex justify-center items-center text-white ml-[-11px] border border-border p-[1px] text-[10px]`}
                  key={item.id}
                >
                  {item.name.charAt(0)}
                </div>
              ))}
              {clients.length > 4 && (
                <div className='w-6 h-6 rounded-full bg-[#3741519c] flex justify-center items-center text-white ml-[-9px] border border-border p-[1px] text-[10px]'>
                  +{clients.length}
                </div>
              )}
            </div>
          </TooltipTrigger>
          <TooltipContent className='z-[9999]'>
            <p>Select Client</p>
          </TooltipContent>
        </Tooltip>
        <div className='hidden md:flex'>
          <SearchBar routes={navDatas} />
        </div>
        <ThemeToggle />
        {/*<UserNav />*/}
        {/*<ThemeToggle />*/}
      </div>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder='Type a command or search...' />
        <CommandList>
          <CommandGroup heading='Clients'>
            {clients.map((item: any) => (
              <div
                onClick={() => {
                  handleChangeToken(item.id);
                }}
              >
                <CommandItem className='cursor-pointer'>
                  <Avatar className='h-8 w-8 !radius-[20px]'>
                    <AvatarFallback>{item.name.charAt(0)}</AvatarFallback>
                  </Avatar>
                  <span>{item.name}</span>
                </CommandItem>
              </div>
            ))}
          </CommandGroup>
          <CommandEmpty>No results found.</CommandEmpty>
        </CommandList>
      </CommandDialog>
    </header>
  );
}
