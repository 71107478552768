import { useAuthContext } from '@context/auth/use-auth-context.ts';
import {
  Alert,
  SidebarInset,
  SidebarProvider
} from '@growdash/design-system/atoms';
import '@growdash/design-system/globals.css';
import { AppSidebar } from '@growdash/design-system/molecules';
import { usePathname, useRouter } from '@hooks/routes';
import { useJune } from '@hooks/use-june.ts';
import { useNavData } from '@hooks/use-nav-data.tsx';
import { ManagementService } from '@modules/management/api';
import { paths } from '@routes/paths.ts';
import axiosInstance, { endpoints } from '@utils/axios.ts';
import { jwtDecode, removeCookie, setSession } from '@utils/jwt';
import { enqueueSnackbar } from 'notistack';
import posthog from 'posthog-js';
import { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import Header from './header.tsx';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

type NavItem = {
  title: string;
  path: string;
  icon?: React.ReactElement;
  permissions: string[];
  hasChildren?: boolean;
};

export default function DashboardLayout({ children }: Props) {
  const router = useRouter();
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(
    null
  );
  const [allClients, setAllClients] = useState<any[]>([]);
  const { user, logout } = useAuthContext();
  const navData = useNavData();
  const juneHook = useJune('q3ClmW5XJpuu6QHF') as any;
  const pathname = usePathname();

  const handleResetFilters = useCallback(() => {
    const path = pathname || window.location.pathname;
    router.push(path);
  }, [pathname, router]);

  const filterNavDataByPermissions = () => {
    return navData
      .map(section => ({
        ...section,
        items: (section.items as NavItem[]).filter(
          item =>
            item?.permissions?.some(permission =>
              user?.permissions?.includes(permission)
            ) ?? false
        )
      }))
      .filter(section => section.items.length > 0);
  };

  const fetchSubscriptionStatus = async () => {
    try {
      const subResponse = await ManagementService.fetchSubscriptionStatus();
      setSubscriptionStatus(subResponse.status);
    } catch (error) {
      enqueueSnackbar(`Error fetching subscription status: ${error}`, {
        variant: 'error'
      });
    }
  };
  useEffect(() => {
    fetchSubscriptionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = async () => {
    ReactGA.set({ userId: null });
    logout();
    posthog.reset();
    removeCookie();
    router.push(paths?.auth?.jwt?.login);
  };

  const getClients = useCallback(async () => {
    try {
      if (user?.isCustomerSuccessUser) {
        const response = await axiosInstance.get(
          endpoints.AUTH.GET_CSM_CLIENTS
        );
        setAllClients(response.data);
      } else {
        const response = await axiosInstance.get(
          endpoints.AUTH.GET_ALL_CLIENTS
        );
        setAllClients(response.data);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeToken = useCallback(
    async (id: number) => {
      handleResetFilters();
      try {
        const response = await axiosInstance.post(
          endpoints.AUTH.GET_CLIENT_TOKEN,
          {
            newClientId: id
          }
        );
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { access_token } = response.data;
        if (access_token) {
          setSession(access_token);
          const tokenUser = jwtDecode(access_token);
          if (juneHook) {
            juneHook.identify(tokenUser.username.toLowerCase(), {
              email: tokenUser.username,
              CSUser: tokenUser.name,
              country: tokenUser.country_name,
              isCustomerSuccessorUser: tokenUser.isCustomerSuccessUser
                ? 'Yes'
                : 'No'
            });
            juneHook.track(
              'CS visited Client',
              {
                visitedClient: tokenUser.client_name,
                CS_user: tokenUser.name
              },
              {
                // Add the GROUP_ID here to track this event on behalf of a workspace
                context: {
                  groupId: `Customer Service`
                }
              }
            );
          }
          window.location.reload();
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, handleResetFilters, juneHook]
  );

  const filteredNavData = filterNavDataByPermissions();

  return (
    <>
      <SidebarProvider>
        <AppSidebar
          user={user}
          handleLogout={handleLogout}
          navDatas={filteredNavData}
          activeTab={pathname}
        />
        <SidebarInset>
          <Header
            handleChangeToken={handleChangeToken}
            clients={allClients}
            navDatas={filteredNavData}
          />
          {subscriptionStatus === 'unpaid' && (
            <Alert variant='warning'>
              Your subscription payment is pending. Please check your payment
              method.
            </Alert>
          )}
          <div className='h-[calc(100dvh-52px)] p-4 container mx-auto'>
            {children}
          </div>
        </SidebarInset>
      </SidebarProvider>
    </>
  );
}
