import { useLottie } from 'lottie-react';
import loading from '../../../../public/animations/loading.json';
import { TextShimmer } from '../../atoms';

const Animation = () => {
  const style = {
    height: 300
  };
  const options = {
    animationData: loading,
    loop: true,
    autoplay: true
  };

  const { View } = useLottie(options, style);

  return <>{View}</>;
};

export default function LoadingScreen() {
  return (
    <div
      style={{
        animationDelay: '60ms',
        animationDuration: '200ms'
      }}
      className='flex justify-center opacity-0 items-center h-screen bg-sidebar bg-opacity-80 absolute inset-0 z-50 animate-fade-in'
    >
      <TextShimmer
        duration={4}
        className='absolute tracking-tighter left-[47%] top-[48%] text-xl font-semibold [--base-color:theme(colors.blue.600)] [--base-gradient-color:theme(colors.blue.200)] dark:[--base-color:theme(colors.blue.700)] dark:[--base-gradient-color:theme(colors.blue.400)]'
      >
        Loading...
      </TextShimmer>
      <Animation />
    </div>
  );
}
